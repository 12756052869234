import {autoinject, bindable} from "aurelia-framework";
import {I18N} from "aurelia-i18n";
import {DialogController, DialogService} from "aurelia-dialog";
import {ValidationController, ValidationControllerFactory, ValidationRules, Validator} from "aurelia-validation";
import {UserService} from "../resources/services/UserService";
import {NitTools} from "../resources/classes/NursitTools";
import * as moment from "moment";
import {RuntimeInfo} from "../resources/classes/RuntimeInfo";
import {BootstrapFormRenderer} from "../resources/classes/bootstrap-form-renderer";
import {ChangePassword} from "./changePassword";
import {FhirService} from "../resources/services/FhirService";
import {DialogMessages} from "../resources/services/DialogMessages";

@autoinject
export class PractitionerEdit {
    rulez;
    validator: Validator;
    validationController: ValidationController;
    dpOptions: any;
    familyName: string;
    givenName: string;
    gender: ('male'|'female'|'other'|'unknown') | undefined;
    phone: string;
    mobile: string;
    mail: string;
    born: Date;    
    isValid: boolean = true;

    @bindable practitioner: any;

    practitionerChanged() {
        if (!this.practitioner) return;
        if (!this.practitioner.name) this.practitioner.name = [{family: '', given: ['']}];
        this.born = this.practitioner.birthDate ? moment(this.practitioner.birthDate).toDate() : undefined;
        this.familyName = this.practitioner.name[0].family;
        this.givenName = this.practitioner.name[0].given[0];
        this.gender = this.practitioner.gender;
    }

    changePass() {
        this.dialogService.open({
            viewModel: ChangePassword,
            lock: true
        });
    }

    async submit() {
        await this.validationController.validate()
            .then(o => {
                if (o.valid) {
                    if (!UserService.Practitioner.name) {
                        UserService.Practitioner.name = [{family: this.familyName, given: [this.givenName]}];
                    }

                    UserService.Practitioner.name[0].family = this.familyName;
                    UserService.Practitioner.name[0].given[0] = this.givenName;
                    if (this.born) UserService.Practitioner.birthDate = moment(this.born).format("YYYY-MM-DD");
                    if (this.gender) UserService.Practitioner.gender = this.gender;
                    this.fhirService.update(UserService.Practitioner)
                        .then(result => {
                            UserService.Practitioner = <any>result;
                            UserService.UserFirstName = this.givenName;
                            UserService.UserLastName = this.familyName;

                            this.controller.ok(`${this.familyName}, ${this.givenName}`);
                        })
                        .catch(error => {
                            this.dialogMessages.prompt(this.i18n.tr('error_updating_user'), this.i18n.tr('warning'), true);
                            console.warn(error);
                        })
                        .finally(() => RuntimeInfo.IsLoading = false);
                }
            });
    }

    cancel() {
        this.controller.cancel();
    }

    attached() {
        this.practitioner = NitTools.Clone(UserService.Practitioner);
    }

    constructor(public controller: DialogController,
                public dialogService: DialogService,
                public dialogMessages: DialogMessages,
                public i18n: I18N,
                validationControllerFactory: ValidationControllerFactory,
                validator: Validator,
                public fhirService: FhirService) {
        this.dpOptions = {
            format: RuntimeInfo.DateFormat
        };

        this.validator = validator;
        this.validationController = validationControllerFactory.createForCurrentScope(this.validator);

        this.rulez = ValidationRules
            .ensure('givenName').required().withMessage(this.i18n.tr('validation_error_first_name'))
            .ensure('familyName').required().withMessage(this.i18n.tr('validation_error_last_name'))
            .rules;

        this.validationController.addRenderer(new BootstrapFormRenderer());
        this.validationController.addObject(this, this.rulez);
    }
}
